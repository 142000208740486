import imgArrow from 'assets/images/become-host/arrow.svg';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMedia } from 'react-use';

export default function TransitionSection() {
  const { t } = useTranslation();
  const isMobile = useMedia('(max-width: 600px)');

  return (
    <div
      className="py-16 px-6"
      style={{
        backgroundColor: '#ffffff',
        paddingBottom: isMobile ? 0 : '',
        paddingTop: isMobile ? 0 : '',
      }}
    >
      <div className="max-w-5xl mx-auto">
        <div
          className="grid grid-cols-2 items-start"
          style={{
            display: isMobile ? 'flex' : '',
            flexWrap: isMobile ? 'wrap' : '',
          }}
        >
          <div>
            <h1
              className="font-bold mt-0"
              dangerouslySetInnerHTML={{
                __html: t('becomeHost.transition.header'),
              }}
            />
          </div>

          <div className="text-brand-t">
            <p>{t('becomeHost.transition.line1')}</p>
            <p className="mt-4">{t('becomeHost.transition.line2')}</p>
          </div>
        </div>

        <div className="flex justify-center">
          <img
            className="-ml-44"
            height="108"
            width="131"
            src={imgArrow}
            alt=""
          />
        </div>

        <h1 className="mb-20 text-4xl text-center font-bold">
          {t('becomeHost.ourEngagements')}
        </h1>
      </div>
    </div>
  );
}
