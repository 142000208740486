import React from 'react';
import { InView } from 'react-intersection-observer';

export default function BaseSection({
  sectionId,
  setSectionSelected,
  children,
  threshold,
  rootMargin,
}) {
  return (
    <InView
      as="div"
      threshold={threshold}
      id={sectionId}
      rootMargin={rootMargin}
      onChange={inView => {
        if (inView) {
          setSectionSelected(sectionId);
        }
      }}
    >
      {children}
    </InView>
  );
}
