import React, { useState } from 'react';
import Button from '../UI/Button';
import imgHighlight from 'assets/images/become-host/highlight.svg';
import imgTree from 'assets/images/arbre.png';
import Input from '../UI/Input';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import { createContact } from 'api/contact';

export default function JoinUsSection() {
  const { t } = useTranslation();

  const [error, setError] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const submit = (values) => {
    setError(false);

    createContact(values)
      .then(() => setSubmitted(true))
      .catch(() => {
        setError(true);
        setSubmitted(false);
      });
  }

  return (
    <div className="tw">
      <div className="bg-gray-100 py-36 px-6">
        <div className="max-w-6xl mx-auto">
          {!submitted ? (
            <>
              <div className="text-center max-w-xl mx-auto">
                <h1 className="font-bold">
                  {t('becomeHost.joinUs.title')}
                  <img className="inline -mt-16 -ml-1" height="50" width="50" src={imgHighlight} alt='' />
                </h1>
                <p className="text-brand-t">
                  {t('becomeHost.joinUs.description')}
                </p>
              </div>
              <div className="py-16 max-w-3xl mx-auto">
                <Formik
                  initialValues={{
                    first_name: '',
                    last_name: '',
                    role: '',
                    email: '',
                    phone: '',
                    establishment_name: '',

                  }}
                  validate={() => {}}
                  validateOnBlur={false}
                  onSubmit={v => submit(v)}
                >
                  {({isSubmitting}) => (
                    <Form>
                      <div className="sm:grid sm:grid-cols-2 gap-8">
                        <Input
                          label={t('becomeHost.joinUs.form.label.firstname')}
                          name="first_name"
                          fieldClass="bg-gray-100 border-gray-200"
                          autoplaceholder
                          required
                        />

                        <Input
                          label={t('becomeHost.joinUs.form.label.lastname')}
                          name="last_name"
                          fieldClass="bg-gray-100 border-gray-200"
                          autoplaceholder
                          required
                        />

                        <Input
                          label={t('becomeHost.joinUs.form.label.role')}
                          name="role"
                          fieldClass="bg-gray-100 border-gray-200"
                          autoplaceholder
                        />

                        <Input
                          label={t('becomeHost.joinUs.form.label.email')}
                          name="email"
                          type="email"
                          fieldClass="bg-gray-100 border-gray-200"
                          autoplaceholder
                          required
                        />

                        <Input
                          label={t('becomeHost.joinUs.form.label.phone')}
                          name="phone"
                          type="tel"
                          fieldClass="bg-gray-100 border-gray-200"
                          autoplaceholder
                        />

                        <Input
                          label={t('becomeHost.joinUs.form.label.establishment_name')}
                          name="establishment_name"
                          placeholder={t('becomeHost.joinUs.form.placeholder.establishment_name')}
                          fieldClass="bg-gray-100 border-gray-200"
                          required
                        />

                        <Input
                          label={t('becomeHost.joinUs.form.label.website')}
                          name="website"
                          placeholder={t('becomeHost.joinUs.form.placeholder.website')}
                          fieldClass="bg-gray-100 border-gray-200"
                        />

                        <Input
                          label={t('becomeHost.joinUs.form.label.channel_manager')}
                          name="channel_manager"
                          placeholder={t('becomeHost.joinUs.form.placeholder.channel_manager')}
                          fieldClass="bg-gray-100 border-gray-200"
                        />
                      </div>

                      <div className="my-8">
                        <Input
                          as="textarea"
                          rows="5"
                          label={t('becomeHost.joinUs.form.label.message')}
                          name="message"
                          type="text"
                          placeholder={t('becomeHost.joinUs.form.placeholder.message')}
                          fieldClass="bg-gray-100 border-gray-200"
                        />
                      </div>

                      {error && (
                        <div className="mb-8 text-red-500 font-semibold text-center">
                          {t('becomeHost.joinUs.error')}
                        </div>
                      )}

                      <div className="text-center">
                        <Button type="submit" loading={isSubmitting}>
                          {t('becomeHost.joinUs.form.submitButton')}
                        </Button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </>
          ) : (
            <div className="py-32 max-w-3xl mx-auto">
              <div className="text-center">
                <img height="100" width="100" src={imgTree} alt='' />
                <h1 className="font-bold">
                  {t('becomeHost.joinUs.submitted.title')}
                </h1>
                <p className="text-brand-t">
                  {t('becomeHost.joinUs.submitted.description')}
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
