import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import highlighting from 'assets/images/highlighting.png';
import React from 'react';

export default function HeroHeader() {
  const { t } = useTranslation();

  const MainTitle = styled.h1`
    color: #fff;
    max-width: 15em;
    margin: 0;
    ${({ theme }) => theme.fontStyles.h1Ultra};
  `;

  const SubTitleContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content:center;
    background-image:url('${highlighting}');
    background-repeat: no-repeat;
    background-size:100% 100%;
    margin-top:0px !important;
    padding:15px;
  `;

  const SubTitleText = styled.h6`
    color: #ffffff;
    padding-left: 25px;
    padding-right: 25px;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    ${({ theme }) => theme.fontStyles.h1Ultra};
  `;

  return (
    <div className="bg-white pb-16 pt-16 md:pt-0">
      <div className="mx-10" style={{height: '541px', borderRadius: '50px', backgroundImage: 'url(https://images.pexels.com/photos/235621/pexels-photo-235621.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940)'}}>
        <div className="h-full flex items-center justify-center">
          <div className="text-center">
            <MainTitle>{t('becomeHost.hero.become')}</MainTitle>
            <SubTitleContainer>
              <SubTitleText>{t('becomeHost.hero.feelingooder')}</SubTitleText>
            </SubTitleContainer>
          </div>
        </div>
      </div>
    </div>
  )
}
