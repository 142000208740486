import React, { useContext, useEffect, useState } from 'react';

import imgEngagement1 from 'assets/images/become-host/engagement-1.svg';
import imgEngagement2 from 'assets/images/become-host/engagement-2.svg';
import imgEngagement3 from 'assets/images/become-host/engagement-3.svg';
import imgEngagement4 from 'assets/images/become-host/engagement-4.svg';
import imgEngagement5 from 'assets/images/become-host/engagement-5.svg';
import imgEngagement6 from 'assets/images/become-host/engagement-6.svg';
import { getEngagementBlocks } from 'api/general';
import PageContext from 'contexts/page';
import styled, { css } from 'styled-components';
import BaseSection from 'components/Hotel/base-section';
import { media } from 'ui';
import { useMedia } from 'react-use';

const ImageBlock = styled.div`
  ${media.desktop`
    position:absolute;
    left: 50%;
    top: 1300px;
    border-radius: 25px;
    ${props =>
      props.isFixed &&
      css`
        position: fixed;
        top: 200px;
        border-radius: 25px;
        z-index: -1;
      `}

    ${props =>
      !props.selected &&
      css`
        ${media.desktop`
        z-index: -2;
        display: none;
        `};
      `}
    `};
`;

const Item = styled.div`
  ${props =>
    props.selected &&
    css`
      ${media.desktop`
        display:block;
      `};
    `}
`;

export default function EngagementsSection() {
  const { language } = useContext(PageContext);
  const [blocks, setBlocks] = useState([]);
  const isMobile = useMedia('(max-width: 600px)');
  const isDesktop = useMedia('(min-width: 1100px)');
  const [sectionSelected, setSectionSelected] = useState('section_0');

  useEffect(() => {
    async function fetch() {
      return await getEngagementBlocks(language);
    }
    fetch().then(b => setBlocks(b.engagementBlocks));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    window.addEventListener('scroll', imageFixed);
    return () => window.removeEventListener('scroll', imageFixed);
  }, []);

  const [isFixed, setIsFixed] = useState('');

  const imageFixed = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      setIsFixed(windowHeight > 1200);
    }
  };

  const engagementNumbers = [
    imgEngagement1,
    imgEngagement2,
    imgEngagement3,
    imgEngagement4,
    imgEngagement5,
    imgEngagement6,
  ];
  var lastImage = '';
  return (
    <div className="py-16 px-6">
      <div className="max-w-5xl mx-auto">
        <div className="flex flex-col space-y-16 space-x-2">
          {blocks.length > 0 &&
            blocks.map((e, i) => (
              <div
                className="lg:flex justify-between items-center"
                key={e.title}
              >
                <BaseSection
                  sectionId={'section_' + i}
                  setSectionSelected={setSectionSelected}
                  threshold={0}
                  rootMargin={'-40% 0px -60%'}
                >
                  <div className="max-w-sm">
                    <h3 className="font-bold" style={{ fontSize: '40px' }}>
                      <img
                        className="h-6 w-auto mr-3"
                        src={engagementNumbers[i] ?? ''}
                        alt=""
                      />
                      {e.title}
                    </h3>
                    <div
                      className="mt-4 text-brand-t"
                      style={{ fontSize: '16px' }}
                      dangerouslySetInnerHTML={{ __html: e.content }}
                    />
                  </div>
                </BaseSection>
                <ImageBlock
                  isFixed={isFixed}
                  selected={sectionSelected === 'section_' + i}
                >
                  {e.attachment &&
                    ((lastImage = e.attachment?.largeUrl),
                    (
                      <Item selected={sectionSelected === 'section_' + i}>
                        <img
                          className="bg-gray-200 rounded-xl object-cover"
                          style={{
                            height: 555,
                            width: isMobile ? '100%' : 475,
                          }}
                          src={e.attachment?.largeUrl}
                          alt="background"
                        />
                      </Item>
                    ))}
                  {!e.attachment && lastImage && isDesktop && (
                    <Item>
                      <img
                        className="bg-gray-200 rounded-xl object-cover"
                        style={{
                          height: 555,
                          width: isMobile ? '100%' : 475,
                        }}
                        src={lastImage}
                        alt="background"
                      />
                    </Item>
                  )}
                </ImageBlock>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}
