import React from 'react';
import { useTranslation } from 'react-i18next';
import Layout from 'components/Layout';
import SEO from 'components/seo';
import HeroHeader from 'components/BecomeHost/hero-header';
import TransitionSection from 'components/BecomeHost/transition-section';
import EngagementsSection from 'components/BecomeHost/engagements-section';
import JoinUsSection from 'components/BecomeHost/join-us-section';

export default function BecomeHost() {
  const { t } = useTranslation();

  return (
    <Layout className="tw" footerVariant="home">
      <SEO title={t('pageTitle.becomeHost')} />

      <HeroHeader/>

      <TransitionSection/>

      <EngagementsSection/>

      <JoinUsSection/>
    </Layout>
  )
}
